<template>
    <div class="group">
        <div class="tabs">
            <ul>
                <li @click="_tabsActive(0)" :class="tabsActive == 0 ? 'active' : ''">
                    群管理
                </li>
                <li @click="_tabsActive(1)" :class="tabsActive == 1 ? 'active' : ''">
                    群消息
                </li>
            </ul>
        </div>
        <div class="content">
            <div class="tree">
                <p class="company">公司组织架构</p>
                <a-tree
                        v-show="tabsActive == 0"
                        :treeData="treeData"
                        defaultExpandAll
                        @select="_treeSelect"
                />
            </div>
            <div class="list">
                <ul class="list-title">
                    <li
                            @click="_searchType(1)"
                            :class="SearchType == 1 ? 'title-active' : ''"
                    >
                        <span>总群数</span>
                        <span class="typeNum">{{ chat_total }}</span>
                    </li>
                    <li
                            @click="_searchType(5)"
                            :class="SearchType == 5 ? 'title-active' : ''"
                    >
                        <span>已加企业微信</span>
                        <span class="typeNum">{{ user_total }}</span>
                    </li>
                    <li
                            @click="_searchType(6)"
                            :class="SearchType == 5 ? 'title-active' : ''"
                    >
                        <span>未加企业微信</span>
                        <span class="typeNum">{{ un_user_total }}</span>
                    </li>
                    <li
                            @click="_searchType(2)"
                            :class="SearchType == 2 ? 'title-active' : ''"
                    >
                        <span>新建加群</span>
                        <span class="typeNum">{{ new_chat_cnt }}</span>
                    </li>
                    <li
                            @click="_searchType(3)"
                            :class="SearchType == 3 ? 'title-active' : ''"
                    >
                        <span>未加群会员</span>
                        <span class="typeNum">{{ un_add_count }}</span>
                    </li>
                    <li
                            @click="_searchType(4)"
                            :class="SearchType == 4 ? 'title-active' : ''"
                    >
                        <span>离职未分配</span>
                        <span class="typeNum">{{ un_dis_count }}</span>
                    </li>
                </ul>
                <div style="clear: both"></div>
                <div class="search" style="display: block">
                    <a-date-picker
                            class="search-style"
                            @change="changeAddTime"
                            placeholder="添加日期"
                            style="width: 120px"
                    />
                    <a-input
                            v-model="name"
                            class="search-style"
                            placeholder="请输入员工姓名"
                            style="width: 120px"
                    />
                    <a-button class="search-style" @click="clear_user_list"
                    >重置
                    </a-button
                    >
                    <a-button type="primary" @click="search_user_list">查询</a-button>
                    <a-button
                            v-if="SearchType == 4"
                            type="primary"
                            style="float: right"
                            @click="distribution('more')"
                    >批量分配
                    </a-button
                    >
                </div>
                <!--总群数start-->
                <div v-if="SearchType == 1 || SearchType == 2">
                    <a-table
                            :pagination="false"
                            v-if="listData"
                            :dataSource="listData"
                            bordered
                    >
                        <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                        <a-table-column key="Name" title="员工" data-index="Name"/>
                        <a-table-column
                                key="schoolName"
                                title="中心"
                                data-index="schoolName"
                        />

                        <a-table-column
                                key="group_count"
                                :title="SearchType == 1 ? '群数' : '新建群数'"
                        >
                            <template slot-scope="text, record">
                <span>
                  <a @click="_groupList(record.userid, record.Name)">{{
                    record.group_count
                  }}</a>
                </span>
                            </template>
                        </a-table-column>
                        <a-table-column key="contact_count" title="客户数">
                            <template slot-scope="text, record">
                <span>
                  <a @click="_contactList(record.userid, record.Name)">{{
                    record.contact_count
                  }}</a>
                </span>
                            </template>
                        </a-table-column>
                        <a-table-column
                                key="UserState"
                                title="状态"
                                data-index="UserState"
                        />
                    </a-table>
                    <a-pagination
                            v-if="pageTotal"
                            style="margin-top: 35px;text-align: right;"
                            @change="ChangePage"
                            :pageSize="Limit"
                            :total="pageTotal"
                            show-less-items
                    />
                </div>
                <!--总群数end-->
                <!--总会员数start-->
                <div v-if="SearchType == 5">
                    <a-table
                            :pagination="false"
                            v-if="user_total_data"
                            :dataSource="user_total_data"
                            bordered
                    >
                        <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                        <a-table-column key="action" title="客户名称">
                            <template slot-scope="text, record">
                                <a-avatar :size="24" :src="record.avatar"/>&nbsp;&nbsp;
                                {{ record.name }}
                            </template>
                        </a-table-column>
                        <a-table-column key="username" title="员工" data-index="username"/>
                        <a-table-column key="gender" title="性别">
                            <template slot-scope="text, record">
                                <span>{{ record.gender == 1 ? '男' : '女' }}</span>
                            </template>
                        </a-table-column>
                        <a-table-column key="remark" title="备注" data-index="remark"/>
                        <a-table-column
                                key="createtime"
                                title="添加时间"
                                data-index="createtime"
                        />
                    </a-table>
                    <a-pagination
                            v-if="pageTotal"
                            style="margin-top: 35px;text-align: right;"
                            @change="ChangePage"
                            :pageSize="Limit"
                            :total="pageTotal"
                            show-less-items
                    />
                </div>
                <!--总会员数end-->

                <!--离职列表start-->
                <div v-if="SearchType == 4 || SearchType == 3">
                    <a-table
                            v-if="un_data"
                            :pagination="false"
                            :data-source="un_data"
                            :row-selection="onSelectChange"
                            bordered
                    >
                        <a-table-column key="sNumber" title="序号" data-index="sNumber"/>

                        <a-table-column key="action" title="会员名称">
                            <template slot-scope="text, record">
                                <a-avatar :size="24" :src="record.avatar"/>&nbsp;&nbsp;
                                {{ record.name ? record.name : record.remark }}
                            </template>
                        </a-table-column>
                        <a-table-column key="mobiles" title="手机号" data-index="mobiles"/>
                        <a-table-column
                                key="username"
                                title="添加人"
                                data-index="username"
                        />
                        <a-table-column
                                key="createtime"
                                title="添加时间"
                                data-index="createtime"
                        />
                        <a-table-column key="userid" title="操作">
                            <template slot-scope="text, record">
                <span>
                  <a @click="distribution(record.sNumber)">分配</a>
                </span>
                            </template>
                        </a-table-column>
                    </a-table>
                    <a-pagination
                            v-if="pageTotal"
                            style="margin-top: 35px;text-align: right;"
                            @change="ChangePage"
                            :pageSize="Limit"
                            :total="pageTotal"
                            show-less-items
                    />
                </div>
                <!--离职列表end-->
            </div>
        </div>
        <!--会员分配给个人start-->
        <a-drawer
                title="会员分配"
                width="520"
                :closable="false"
                :visible="dis_visible"
                @close="onClose"
        >
            <div class="flex" style="margin-bottom: 20px;display: block">
                <span style="width: 80px;display: inline-block">会员名称</span>
                <a-tag
                        v-if="dis_data.length > 0"
                        style="margin-bottom: 10px"
                        v-for="item in dis_data"
                >{{ item.name ? item.name : item.remark }}
                </a-tag
                >
            </div>
            <div class="flex">
                <span style="width: 80px;display: inline-block">分配人</span>
                <person v-bind="personObj" @getData="ChangeUser"></person>
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="dis_onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="to_distribution">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--会员分配给个人end-->
        <!--群列表start-->
        <a-drawer
                :title="groupLeader ? groupLeader + '的群' : ''"
                width="1000"
                :closable="false"
                :visible="visible"
                @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <div class="search" style="margin-top: 0;display: block">
                    <a-date-picker
                            class="search-style"
                            @change="changeAddTime"
                            placeholder="建群日期"
                            style="width: 120px"
                    />
                    <a-input
                            class="search-style"
                            v-model="groupName"
                            placeholder="搜索群名称"
                            style="width: 120px"
                    />
                    <a-button class="search-style" @click="_groupClear">重置</a-button>
                    <a-button type="primary" @click="_groupSearch">查询</a-button>
                    <a-button
                            type="primary"
                            style="float:right;"
                            @click="dis_group('more')"
                    >批量分配
                    </a-button
                    >
                </div>
                <a-table
                        v-if="groupData"
                        :row-selection="groupSelectChange"
                        :data-source="groupData"
                        :pagination="false"
                        bordered
                >
                    <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                    <a-table-column key="name" title="群名称" data-index="name"/>
                    <a-table-column key="username" title="群主" data-index="username"/>
                    <a-table-column key="member_count" title="人数">
                        <template slot-scope="text, record">
              <span>
                <a @click="group_user(record.chat_id, record.name)">{{
                  record.member_count
                }}</a>
              </span>
                        </template>
                    </a-table-column>
                    <a-table-column
                            key="vs"
                            title="企业成员V外部联系人"
                            data-index="vs"
                    />
                    <!-- <a-table-column key="work_count" title="消息数量" data-index="work_count" /> -->
                    <a-table-column
                            key="create_time"
                            title="群的创建时间"
                            data-index="create_time"
                    />
                    <a-table-column key="notice" title="群备注" data-index="notice"/>

                    <a-table-column key="userid" title="操作">
                        <template slot-scope="text, record">
              <span>
                <a @click="dis_group(record.sNumber)">分配</a>
              </span>
                        </template>
                    </a-table-column>
                </a-table>
                <a-pagination
                        v-if="groupTotal"
                        style="margin-top: 35px;text-align: right;"
                        @change="_groupPage"
                        :pageSize="Limit"
                        :total="groupTotal"
                        show-less-items
                />
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="onClose">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--群列表end-->
        <!--群分配start-->
        <a-drawer
                title="分配信息"
                width="520"
                :closable="false"
                :visible="childrenDrawer"
                @close="childrenClose"
        >
            <div class="flex" style="margin-bottom: 20px;display: block">
                <span style="width: 80px;display: inline-block">群名称</span>
                <!--<a-input v-if="group_info.length>0" :value="group_info[0].name" />-->
                <a-tag
                        v-if="group_info.length > 0"
                        style="margin-bottom: 10px"
                        v-for="item in group_info"
                >{{ item.name }}
                </a-tag
                >
            </div>
            <div class="flex">
                <span style="width: 80px;display: inline-block">群主</span>
                <person v-bind="personObj" @getData="ChangeUser"></person>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="childrenClose">
                    取消
                </a-button>
                <a-button type="primary" @click="toGroup">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--群分配end-->
        <!--客户列表start-->
        <a-drawer
                :title="con_name ? con_name + '的客户' : ''"
                width="1000"
                :closable="false"
                :visible="con_visible"
                @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <div class="search" style="margin-top: 0;display: block">
                    <!--<a-date-picker class="search-style" @change="changeAddTime"  placeholder="建群日期" style="width: 120px" />-->
                    <a-input
                            class="search-style"
                            v-model="con_search_name"
                            placeholder="搜索客户名称"
                            style="width: 120px"
                    />
                    <a-button class="search-style" @click="con_clear">重置</a-button>
                    <a-button type="primary" @click="con_search">查询</a-button>
                    <a-button
                            type="primary"
                            style="float:right;"
                            @click="distribution('more')"
                    >批量分配
                    </a-button
                    >
                </div>
                <!--:row-selection="conSelectChange"-->
                <a-table
                        v-if="con_data"
                        :row-selection="conSelectChange"
                        :data-source="con_data"
                        :pagination="false"
                        bordered
                >
                    <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                    <a-table-column key="action" title="客户名称">
                        <template slot-scope="text, record">
                            <a-avatar :size="24" :src="record.avatar"/>&nbsp;&nbsp;
                            {{ record.name }}
                        </template>
                    </a-table-column>
                    <a-table-column key="name" title="手机号" data-index="mobiles"/>
                    <a-table-column
                            key="username"
                            title="添加时间"
                            data-index="createtime"
                    />
                    <a-table-column key="vs" title="备注" data-index="remark"/>
                    <a-table-column
                            key="work_count"
                            title="最后一次分配人"
                            data-index="last_add"
                    />
                    <a-table-column key="userid" title="操作">
                        <template slot-scope="text, record">
              <span>
                <a @click="distribution(record.sNumber, 'con_data')">分配</a>
              </span>
                        </template>
                    </a-table-column>
                </a-table>
                <a-pagination
                        v-if="contactCount"
                        style="margin-top: 35px;text-align: right;"
                        @change="_conPage"
                        :pageSize="Limit"
                        :total="contactCount"
                        show-less-items
                />
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="con_Close">
                    取消
                </a-button>
                <a-button type="primary" @click="con_Close">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--客户列表end-->
        <!--群成员列表start-->
        <a-drawer
                :title="group_user_name ? group_user_name : ''"
                width="1000"
                :closable="false"
                :visible="group_user_visible"
                @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <!--<div class="search" style="margin-top: 0;display: block">-->
                <!--<a-date-picker class="search-style" @change="changeAddTime"  placeholder="建群日期" style="width: 120px" />-->
                <!--<a-input class="search-style" v-model="group_search_name"  placeholder="搜索群名称" style="width: 120px" />-->
                <!--<a-button class="search-style">重置</a-button>-->
                <!--<a-button type="primary">查询</a-button>-->
                <!--&lt;!&ndash;<a-button type="primary" style="float:right;" @click="dis_group('more')">批量分配</a-button>&ndash;&gt;-->
                <!--</div>-->
                <!--:row-selection="groupSelectChange"-->
                <a-table
                        v-if="group_user_data"
                        :data-source="group_user_data"
                        :pagination="false"
                        bordered
                >
                    <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                    <a-table-column key="action" title="客户名称">
                        <template slot-scope="text, record">
                            <a-avatar :size="24" :src="record.avatar"/>&nbsp;&nbsp;
                            {{ record.name }}
                        </template>
                    </a-table-column>
                    <a-table-column
                            key="join_time"
                            title="添加时间"
                            data-index="join_time"
                    />
                    <a-table-column key="type" title="成员类型" data-index="type"/>
                    <a-table-column
                            key="join_scene"
                            title="加入方式"
                            data-index="join_scene"
                    />
                    <!--<a-table-column key="userid" title="操作" >-->
                    <!--<template slot-scope="text, record">-->
                    <!--<span>-->
                    <!--<a @click="dis_group(record.sNumber)">分配</a>-->
                    <!--</span>-->
                    <!--</template>-->
                    <!--</a-table-column>-->
                </a-table>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="get_user_group_Close">
                    取消
                </a-button>
                <a-button type="primary" @click="get_user_group_Close">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--群成员列表end-->
    </div>
</template>

<script>
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import moment from 'moment'

    export default {
        name: 'group',
        components: {person},
        data() {
            return {
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                tabsActive: 0,
                treeData: [
                    {
                        title: '思可集团',
                        key: '0-0',
                        children: [
                            {
                                title: '七田真事业部',
                                // key: '0-0-0',
                                children: [
                                    {title: 'leaf', key: '0-0-0-0'},
                                    {title: 'leaf', key: '0-0-0-1'}
                                ]
                            },
                            {
                                title: '小王子事业部',
                                key: '0-0-1',
                                children: [{key: '0-0-1-0', title: '市场部'}]
                            }
                        ]
                    }
                ],
                SearchType: 1,
                user_total: 0, //已加企业微信
                un_user_total: 0, //未加企业微信
                user_total_data: '',
                /*总群数*/
                listData: '',
                pageTotal: 0,
                Limit: 10,
                partyid: '',
                date: '',
                name: '',
                page: '',
                /*总群数*/
                /*离职列表*/
                selectedRowKeys: [],
                un_data: '',
                dis_visible: false,
                dis_data: [], //分配的数据
                takeover_userid: '', //接替成员的id
                /*离职列表*/
                chat_total: 0, //群总数
                new_chat_cnt: 0, //新增群数
                un_add_count: 0, //未加群会员
                un_dis_count: 0, //离职会员数
                /*成员群列表成员群列表*/
                visible: false,
                groupData: '',
                groupName: '', //群名称
                groupLeader: '', //群主
                group_info: [], //群分配数据
                childrenDrawer: false,
                groupTotal: '',
                group_userid: '',
                groupPage: '',
                /*成员群列表*/
                /*客户列表*/
                con_visible: false,
                con_name: '',
                con_data: '',
                contactCount: '',
                con_search_name: '',
                con_userid: '',
                con_page: '',
                /*客户列表*/
                /*群成员列表*/
                group_user_data: '',
                group_user_visible: false,
                group_user_name: '', //群名称
                group_search_name: '',
                /*群成员列表*/
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                currDate: ''
            }
        },
        methods: {
            moment
        },
        created() {
            this._loadData()
        },
        mounted() {
            //组织架构
            if (window.sessionStorage.getItem('get_org_list')) {
                this.treeData = JSON.parse(window.sessionStorage.getItem('get_org_list'))
            } else {
                this.$axios.get(1072, {}, res => {
                    if (res.data.list) {
                        this.treeData = res.data.list
                        window.sessionStorage.setItem(
                            'get_org_list',
                            JSON.stringify(res.data.list)
                        )
                    } else {
                        this.$message.error('获取数据失败!')
                    }
                })
            }
        },
        computed: {
            onSelectChange() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.dis_data = selectedRows
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            },
            groupSelectChange() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.group_info = selectedRows
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            },
            conSelectChange() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.dis_data = selectedRows
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            }
        },
        methods: {
            moment,
            _loadData() {
                let self = this
                this.$message.loading('加载中...')
                this.$axios.get(
                    1073,
                    {
                        partyid: self.partyid,
                        date: self.date,
                        name: self.name,
                        page: self.page
                    },
                    res => {
                        console.log(res)
                        this.chat_total = res.data.chat_total //总群数
                        this.new_chat_cnt = res.data.new_chat_cnt //新增群数
                        this.un_add_count = res.data.un_add_count //未加群会员
                        this.un_dis_count = res.data.un_dis_count //离职会员数
                        this.user_total = res.data.user_total // 已加企业微信
                        this.un_user_total = res.data.un_user_total //未加企业微信
                        this.pageTotal = res.data.count
                        this.listData = res.data.list
                        this.$message.destroy()
                    }
                )
            },
            //新建加群
            get_new_group_list() {
                let self = this
                this.$message.loading('加载中...')
                this.$axios.get(
                    1078,
                    {
                        partyid: self.partyid,
                        date: self.date,
                        name: self.name,
                        page: self.page
                    },
                    res => {
                        console.log(res)
                        //this.chat_total = res.data.chat_total;
                        this.chat_total = res.data.list.chat_total //总群数
                        this.new_chat_cnt = res.data.list.new_chat_cnt //新增群数
                        this.un_add_count = res.data.list.un_add_count //未加群会员
                        this.un_dis_count = res.data.list.un_dis_count //离职会员数
                        this.user_total = res.data.list.user_total // 总会员数
                        this.un_user_total = res.data.list.un_user_total //未加企业微信
                        this.pageTotal = res.data.count
                        this.listData = res.data.list.list
                        this.$message.destroy()
                    }
                )
            },
            ChangePage(value) {
                this.page = value
                if (this.SearchType == 1) {
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 4 || this.SearchType == 3) {
                    this.get_unassigned()
                } else if (this.SearchType == 5) {
                    this._user_total()
                }
            },
            clear_user_list() {
                this.page = ''
                this.name = ''
                this.partyid = ''
                this.date = ''
                if (this.SearchType == 1) {
                    this.partyid = e.selectedNodes[0].data.props.value
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 4) {
                    this.get_unassigned()
                } else if (this.SearchType == 5) {
                    this._user_total()
                }
            },
            search_user_list() {
                this.page = ''
                if (this.SearchType == 1) {
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 4 || this.SearchType == 3) {
                    this.get_unassigned()
                } else if (this.SearchType == 5) {
                    this._user_total()
                }
            },
            changeAddTime(time, value) {
                this.date = value
            },
            /*离职列表*/
            get_unassigned() {
                let self = this
                let inGroup = ''
                this.$message.loading('加载中...')
                if (this.SearchType == 3) {
                    inGroup = 2 //未加群会员
                }
                this.$axios.get(
                    1074,
                    {
                        partyid: self.partyid,
                        date: self.date,
                        name: self.name,
                        page: self.page,
                        limit: self.Limit,
                        inGroup: inGroup
                    },
                    res => {
                        this.pageTotal = res.data.count
                        this.un_data = res.data.list.list
                        this.chat_total = res.data.list.chat_total //总群数
                        this.new_chat_cnt = res.data.list.new_chat_cnt //新增群数
                        this.un_add_count = res.data.list.un_add_count //未加群会员
                        this.un_dis_count = res.data.list.un_dis_count //离职会员数
                        this.user_total = res.data.list.user_total // 总会员数
                        this.un_user_total = res.data.list.un_user_total //未加企业微信
                        this.$message.destroy()
                    }
                )
            },
            _treeSelect(key, e) {
                console.log(e)
                if (this.SearchType == 1) {
                    this.partyid = e.selectedNodes[0].data.props.value
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 4) {
                    this.get_unassigned()
                } else if (this.SearchType == 5) {
                    this._user_total()
                }
            },
            //分配
            distribution(i, type) {
                if (i == 'more') {
                    if (this.dis_data.length <= 0) {
                        this.$message.error('请先选择要分配的会员')
                        return false
                    }
                } else {
                    if (type == 'con_data') {
                        let num = i - 1
                        this.dis_data.push(this.con_data[num])
                        this.con_visible = false
                    } else {
                        this.dis_data = []
                        let num = i - 1
                        this.dis_data.push(this.un_data[num])
                    }
                }
                this.dis_visible = true
            },
            to_distribution() {
                let self = this
                if (!this.takeover_userid) {
                    this.$message.error('请选择分配人')
                    return false
                }
                this.$axios.post(
                    1075,
                    {
                        external_info: JSON.stringify(self.dis_data),
                        takeover_userid: self.takeover_userid,
                        userid: self.userinfo.uid,
                        username: self.userinfo.name
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.dis_visible = false
                            self.takeover_userid = ''
                            self.dis_data = []
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            ChangeUser(value) {
                this.takeover_userid = value[0].id
            },
            dis_onClose() {
                this.takeover_userid = ''
                this.dis_data = []
                this.dis_visible = false
            },
            //客户列表
            _contactList(userid, name) {
                this.$message.loading('加载中...')
                if (!this.con_name) {
                    this.con_name = name
                }
                let date = null
                if (this.SearchType == 2) {
                    date = this.__moment__().format('YYYY-MM-DD')
                }

                this.con_userid = userid
                let self = this
                this.$axios.get(
                    1080,
                    {
                        userid: userid,
                        name: self.con_search_name,
                        page: self.con_page,
                        limit: self.Limit,
                        date: date
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.con_data = res.data.list
                            self.contactCount = res.data.count
                            self.con_visible = true
                        } else {
                            self.con_data = ''
                            self.contactCount = ''
                            this.$message.error(res.data.message)
                        }
                        this.$message.destroy()
                    }
                )
            },
            _conPage(value) {
                this.con_page = value
                this._contactList(this.con_userid)
            },
            con_clear() {
                this.con_search_name = ''
                this._contactList(this.con_userid)
            },
            con_search() {
                this.con_page = ''
                this._contactList(this.con_userid)
            },
            con_Close() {
                this.groupLeader = ''
                this.con_data = ''
                this.con_page = ''
                this.con_name = ''
                this.con_userid = ''
                this.con_search_name = ''
                this.con_visible = false
            },
            _tabsActive(i) {
                this.tabsActive = i
            },
            _searchType(i) {
                this.SearchType = i
                // this.partyid = '';
                // this.date='';
                //this.name='';
                this.page = ''
                this.pageTotal = 0
                if (i == 1) {
                    this._loadData()
                } else if (i == 2) {
                    this.get_new_group_list()
                } else if (i == 4 || i == 3) {
                    this.get_unassigned()
                } else if (i == 5) {
                    this._user_total()
                }
            },
            //总会员数
            _user_total() {
                let self = this
                this.$axios.get(
                    '1084',
                    {
                        partyid: self.partyid,
                        date: self.date,
                        name: self.name,
                        page: self.page
                    },
                    res => {
                        console.log(res)
                        this.chat_total = res.data.chat_total //总群数
                        this.new_chat_cnt = res.data.new_chat_cnt //新增群数
                        this.un_add_count = res.data.un_add_count //未加群会员
                        this.un_dis_count = res.data.un_dis_count //离职会员数
                        this.user_total = res.data.user_total // 总会员数
                        this.pageTotal = res.data.count
                        this.user_total_data = res.data.list
                    }
                )
            },
            //群列表
            _groupList(userid, name) {
                this.$message.loading('加载中...')
                let self = this
                if (!this.groupLeader) {
                    this.groupLeader = name
                }
                if (!self.date) {
                    //新建群日期默认
                    if (self.SearchType == 2) {
                        self.date = this._currDate()
                    }
                }
                this.group_userid = userid

                this.$axios.get(
                    1076,
                    {
                        userid: userid,
                        date: self.date,
                        name: self.groupName,
                        status: self.SearchType,
                        page: self.groupPage
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.groupData = res.data.list
                            self.groupTotal = res.data.count
                        } else {
                            self.groupData = ''
                            self.groupTotal = 0
                            this.$message.error(res.data.message)
                        }
                        this.$message.destroy()
                    }
                )
                this.visible = true
            },
            onClose() {
                this.date = ''
                this.groupName = ''
                this.groupPage = ''
                this.groupTotal = ''
                this.groupData = ''
                this.group_userid = ''
                this.groupLeader = ''
                this.visible = false
            },
            _groupClear() {
                this.date = ''
                this.groupName = ''
                this.groupPage = ''
                this._groupList(this.group_userid)
            },
            _groupSearch() {
                this.groupPage = ''
                this._groupList(this.group_userid)
            },
            _groupPage(value) {
                this.groupPage = value
                this._groupList(this.group_userid)
            },
            //群分配
            dis_group(i) {
                if (i != 'more') {
                    let num = i - 1
                    this.group_info.push(this.groupData[num])
                } else {
                    if (this.group_info.length <= 0) {
                        this.$message.error('请先选择要分配的群')
                        return false
                    }
                }

                this.childrenDrawer = true
            },
            toGroup() {
                if (!this.takeover_userid) {
                    this.$message.error('请选择分配人')
                    return false
                }
                let self = this
                this.$axios.post(
                    1077,
                    {
                        group_info: JSON.stringify(self.group_info),
                        new_owner: self.takeover_userid,
                        userid: self.userinfo.uid,
                        username: self.userinfo.name
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.childrenClose()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            //群成员列表
            group_user(chat_id, group_user_name) {
                this.$message.loading('加载中...')
                this.group_user_name = group_user_name
                let self = this
                this.$axios.get(
                    1079,
                    {
                        chat_id: chat_id,
                        name: self.group_search_name,
                        type: ''
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.group_user_visible = true
                            self.group_user_data = res.data.list
                        } else {
                            this.$message.error(res.data.message)
                        }
                        this.$message.destroy()
                    }
                )
            },
            get_user_group_Close() {
                this.group_user_name = ''
                this.group_search_name = ''
                this.group_user_data = ''
                this.group_user_visible = false
            },
            childrenClose() {
                this.takeover_userid = ''
                this.group_info = []
                this.childrenDrawer = false
            },

            handleChange() {
            },
            _currDate() {
                return this.__moment__().format('YYYY-MM-DD')
            }
        }
    }
</script>

<style lang="less" scoped>
    * {
        font-family: Microsoft YaHei;
    }

    .tabs {
        border-bottom: 1px solid #ccd1dc;
        border-left: 1px solid #ccd1dc;
        border-top-left-radius: 5px;

        ul {
            li {
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #ccd1dc;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child {
                    border-top-left-radius: 5px;
                }
                &:last-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active {
                background: #1890ff;
                color: #fff;
                border: 1px solid #1890ff;
            }
        }
    }

    .content {
        margin-top: 15px;
        display: flex;
        min-height: 800px;
        .tree {
            width: 200px;
            background: #ffffff;
            padding: 15px;
            border: 1px solid #dfe0e6;
            border-radius: 5px;
            .company {
                color: #242424;
                font-size: 14px;
            }
        }
        .list {
            padding: 15px;
            background: #ffffff;
            margin-left: 20px;
            flex: 1;
            padding-right: 75px;
            .list-title {
                /*width: 400px;*/
                li {
                    width: 15.8%;
                    margin-right: 1%;
                    float: left;
                    border: 1px solid rgba(224, 234, 244, 1);
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px 0px;
                    cursor: pointer;
                    span {
                        display: block;
                        font-size: 14px;
                        color: rgba(153, 153, 153, 1);
                    }
                    .typeNum {
                        color: rgba(102, 102, 102, 1);
                        font-size: 16px;
                    }
                }
                .title-active {
                    border: 1px solid rgba(41, 148, 255, 1);
                }
                li:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .search {
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        .search-style {
            margin-right: 10px;
        }
    }

    .hook {
        color: rgba(41, 148, 255, 1);
        cursor: pointer;
    }
</style>
